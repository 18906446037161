import React from "react"
import PropTypes from "prop-types"

import "./layout.scss"

const Footer = () => {
  return (
    <footer class=" p-3">
      Mahes Sivakumar © {new Date().getFullYear()} | All rights reserved 
      <br />
      Still in Beta | Send your feedback to{" "}
      <a
        href="mailto:support@appybot.in"
        target="_top"
      >
        support@appybot.in
      </a>
    </footer>
  )
}

const Layout = ({ children }) => {

  return (
    <div className="parent-container container d-flex flex-column justify-content-between">
      <div />
      <div className>
        <main>{children}</main>
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
